export const images = () => {
  // Before After Slider
  var beforeAfterSlider = $(".service-before-after-slider");
  if (beforeAfterSlider.length > 0) {
    beforeAfterSlider.on(
      "edge breakpoint setPosition beforeChange afterChange",
      function (event, slick, currentSlide, nextSlide) {
        $(window).trigger("resize.twentytwenty");
      }
    );
    beforeAfterSlider.on(
      "init",
      function (event, slick, currentSlide, nextSlide) {
        $(".twentytwenty-container").twentytwenty({
          default_offset_pct: 0.5,
          no_overlay: true,
          move_slider_on_hover: false,
          move_with_handle_only: true,
          click_to_move: false,
        });
      }
    );
    beforeAfterSlider.slick({
      slidesToShow: 1,
      focusOnSelect: true,
      swipe: false,
      touchMove: false,
      dots: true,
      infinite: true,
      adaptiveHeight: false,
      arrows: true,
      prevArrow:
        '<div class="slick-next"><i class="fas fa-chevron-right"></i></div>',
      nextArrow:
        '<div class="slick-prev"><i class="fas fa-chevron-left"></i></div>',
    });
  }
};

export const burger = () => {
  const btns = document.querySelectorAll(".header-mobile-burger");
  const menus = document.querySelectorAll(".header-menu-mob");
  // const page = document.querySelector("#page");
  // const languageArrow = document.querySelectorAll(".dropdown-current");

  btns.forEach((btn) => {
    btn.addEventListener("click", () => {
      btn.classList.toggle("active");
      toggleMenu();
    });
  });

  const toggleMenu = () => {
    menus.forEach((menu) => {
      menu.classList.toggle("scale-y-0");
    });
    document.querySelector("body").classList.toggle("overflow-hidden");
  };
};

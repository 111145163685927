import { faq } from "../../src/js/modules/faq.js";
import { burger } from "../../src/js/modules/burger.js";
import { tabs } from "../../src/js/modules/tabs.js";
import { contact } from "../../src/js/modules/contact-form.js";
import { images } from "../../src/js/modules/images.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  tabs();
  faq();
  contact();
  images();
});
